import React from 'react'
import { Brush, Building2, GlobeIcon, Megaphone } from 'lucide-react'

const features = [
  {
    name: 'Social Media Managers',
    description:
      'Streamline your content creation process and stay ahead of trends.',
    icon: <GlobeIcon />,
  },
  {
    name: 'Content Creators',
    description:
      'Boost your creativity and produce engaging memes effortlessly.',
    icon: <Brush />,
  },
  {
    name: 'Businesses',
    description:
      'Connect with your audience through relatable and shareable memes.',
    icon: <Building2 />,
  },
  {
    name: 'Marketers',
    description: 'Enhance your marketing campaigns with viral-worthy memes.',
    icon: <Megaphone />,
  },
]

const WhoNeedsIt: React.FC = () => {
  return (
    <section className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl sm:text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Who needs Supermeme?
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            Supermeme is perfect for anyone looking to enhance their social
            media presence and engage their audience with creative, viral-worthy
            content.
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4'>
            {features.map((item) => (
              <div key={item.name} className='flex flex-col'>
                <dt className='text-base font-semibold leading-7 text-gray-900'>
                  <div className='mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600'>
                    <div className='h-6 w-6 text-white' aria-hidden='true'>
                      {item.icon}
                    </div>
                  </div>
                  {item.name}
                </dt>
                <dd className='mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600'>
                  <p className='flex-auto'>{item.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default WhoNeedsIt
