import React from 'react'
import { Button } from '../ui/button'
import Link from 'next/link'

const MemeAPICTA: React.FC = () => {
  return (
    <section className='py-16 sm:py-24'>
      <div className='mx-auto max-w-5xl rounded-lg bg-indigo-700 px-6 py-12 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
            Boost Your Meme Game with Our API
          </h2>
          <p className='mt-6 text-lg leading-8 text-indigo-200'>
            Integrate our powerful meme generation API into your own
            applications and take your content creation to the next level.
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Button variant='outline' asChild size='lg'>
              <Link href='/pricing'>Get API Access →</Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MemeAPICTA
