import Image from 'next/image'
import { DownloadIcon, SparklesIcon, TypeIcon } from 'lucide-react'

const features = [
  {
    name: 'Input text',
    description:
      'Generate AI memes by simply typing in text in natural language. Our system understands context and meme trends.',
    icon: TypeIcon,
  },
  {
    name: 'AI processing',
    description:
      'Our advanced AI automatically writes the caption and selects the most appropriate meme template based on your input.',
    icon: SparklesIcon,
  },
  {
    name: 'Export meme',
    description:
      'Download your generated meme in various formats suitable for different social media platforms, with customizable watermarks.',
    icon: DownloadIcon,
  },
]

export default function HowItWorks() {
  return (
    <div className='overflow-hidden bg-white py-12 sm:py-16'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
          <div className='lg:pr-8 lg:pt-4'>
            <div className='lg:max-w-lg'>
              <h2 className='text-base font-semibold leading-7 text-indigo-600'>
                How it works
              </h2>
              <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                Generate memes from text input
              </p>
              <p className='mt-6 text-lg leading-8 text-gray-600'>
                Turn your ideas into memes with just a few clicks. Our
                AI-powered system makes meme creation easier than ever.
              </p>
              <dl className='mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none'>
                {features.map((feature) => (
                  <div key={feature.name} className='relative pl-9'>
                    <dt className='inline font-semibold text-gray-900'>
                      <feature.icon
                        className='absolute left-1 top-1 h-5 w-5 text-indigo-600'
                        aria-hidden='true'
                      />
                      {feature.name}
                    </dt>{' '}
                    <dd className='inline'>{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className='w-full overflow-hidden lg:mt-0'>
            <Image
              src='/static/landing-pages/home/how-it-works.jpg'
              alt='Meme generation process - how it works'
              width={2432}
              height={1442}
              sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
              className='h-auto w-full object-contain ring-1 ring-gray-400/10'
              priority
            />
          </div>
        </div>
      </div>
    </div>
  )
}
