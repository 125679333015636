import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { Button } from '../ui/button'
import Link from 'next/link'

const reasons = [
  {
    title: 'Social Media Marketing',
    description:
      'Prospects are more likely to engage with memes on social media. Get an infinite supply of memes.',
  },
  {
    title: 'Outbound Marketing',
    description:
      'Not getting responses on LinkedIn DMs? Try sending a meme instead.',
  },
  {
    title: 'Paid Marketing',
    description:
      "Stand out from the usual 'salesy' ad by using a meme in your digital ads",
  },
]

export default function WhyYouNeedIt() {
  return (
    <div className='bg-white py-12 sm:py-16'>
      <div className='mx-auto max-w-5xl px-6 lg:px-8'>
        <h2 className='mb-12 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
          Why do I need it?
        </h2>
        <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
          {reasons.map((reason, index) => (
            <div
              key={index}
              className={`rounded-lg bg-indigo-600 p-6 ${index === 0 ? 'sm:col-span-2 lg:col-span-1' : ''}`}
            >
              <h3 className='mb-2 text-xl font-semibold text-white'>
                {reason.title}
              </h3>
              <p className='text-indigo-100'>{reason.description}</p>
            </div>
          ))}
        </div>
        <div className='mt-12 text-center'>
          <h3 className='mb-2 text-2xl font-semibold text-gray-900'>
            Meme marketing made easy
          </h3>
          <p className='mb-6 text-gray-600'>
            Supermeme.ai lets you create AI memes to get your prospect&apos;s
            attention and stand out from the regular marketing content your
            competitors put out
          </p>

          <Button asChild>
            <Link href='/meme-marketing'>
              Ultimate Guide to Meme Marketing
              <ArrowRightIcon className='ml-2 h-5 w-5' />
            </Link>
          </Button>
        </div>
      </div>
    </div>
  )
}
